import React from 'react'
import styles from './style.module.scss'
import { ReactComponent as ArrowIcon } from './assets/Arrow.svg'
import { ReactComponent as GoogleIcon } from './assets/Google.svg'

type Props = {
    className?: string
    onClick?: () => void
}

export const ButtonGoogle: React.FC<Props> = ({ className = '', onClick }) => {
    return (
        <div
            className={`${styles.ButtonGoogle} ${className}`}
            onClick={onClick}
        >
            <GoogleIcon />
            <small>Sign in with Google</small>
            <ArrowIcon />
        </div>
    )
}
