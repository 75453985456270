import { Toaster } from 'react-hot-toast'
import styles from './style.module.scss'

import { ReactComponent as CancelIcon } from './assets/cancel.svg'
import { ReactComponent as CheckIcon } from './assets/check_circle.svg'

export const Notification: React.FC = () => {
    return (
        <Toaster position="top-right">
            {(t: any) => {
                let title = 'Succes'
                let gradientColorClassname = styles.Notification__gradient_green
                let icon = <CheckIcon />

                if (t.type === 'error') {
                    gradientColorClassname = styles.Notification__gradient_red
                    title = 'Error'
                    icon = <CancelIcon />
                }

                return (
                    <div className={styles.Notification}>
                        <div
                            className={
                                styles.Notification__gradient +
                                ' ' +
                                gradientColorClassname
                            }
                        ></div>
                        <div className={styles.Notification__icon}>{icon}</div>
                        <div className={styles.Notification__textContainer}>
                            <p className={styles.Notification__title}>
                                {title}
                            </p>
                            <p className={styles.Notification__sub}>
                                {t.message}
                            </p>
                        </div>
                    </div>
                )
            }}
        </Toaster>
    )
}
