import React from "react";
import styles from "../../pages/GenerationBlock/pages/ShadowGPT/style.module.scss";

export const PlanBadge: React.FC = () => {
  return (
    <div className={styles.ShadowGPT__tariffContainer_indicatorContainer}>
      <small>Basic Plan</small>
      <div className={styles.ShadowGPT__tariffContainer_indicator} />
      <small>0 / 150 requests</small>
    </div>
  );
};
